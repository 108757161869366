<template>
  <div class="navigation dp-f ai-c">
    <div class="dp-f ai-c cu-p mr-42" @click="router_push_name('operation_sign', undefined, undefined, true)">
      <img src="@/assets/img/general/left.png">
      <div class="pb-1 ml-2">返回上一页</div>
    </div>
    <div>
      <div class="title dp-f mb-4">{{ props.baseInfo.contractName }}<labelIcon class="ml-4"
          :title="getcontractStatus(props.baseInfo.contractStatus)" :CSStype="props.baseInfo.contractStatus"></labelIcon>
      </div>
      <div class="c-666">{{ props.baseInfo.contractTypeStr }} ｜ 合同编号 {{ props.baseInfo.contractNumber || '暂无' }}</div>
    </div>
    <!-- route.params.type{{ route.params }} -->
    <div class="ml-at dp-f">
      <!-- <oabutton width='96' height='42' style="border-radius: 2px;" title="撤回" CSStype=3 v-permission="'contractDocuments:edit'"></oabutton> -->
      <!-- <oabutton width='96' height='42' style="border-radius: 2px;" title="合同预览" CSStype=3 class="ml-14"></oabutton> -->
      <!-- {{ props.baseInfo.id }} -->
      <oabutton width='118' height='42' style="border-radius: 2px;" title="合同下载" CSStype=3 class="ml-14"
        v-if="props.baseInfo.contractStatus == 2" @click="batchDownloadFile([props.baseInfo.id])"></oabutton>
      <oabutton width='118' height='42' style="border-radius: 2px;" title="签署" CSStype=2 class="ml-14"
        @buttonclick="emit('SignEmit')" v-if="route.params.type == 1" :disabled="state.checkIs" @click="butClick"></oabutton>
    </div>
  </div>
</template>
<script setup>
import { ref,reactive,computed, unref,watch, defineEmits } from "vue";
import { useRoute } from "vue-router";
import { router_push_name } from "@/utils/server/router.js"
import { getcontractStatus } from "@/utils/server/getcode.js"
import { batchDownloadFile } from '@/utils/server/upload'
import { useStore } from "vuex";
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import labelIcon from "@/components/icon/label.vue"
const route = useRoute()
const store = useStore();
const emit = defineEmits(['SignEmit'])
const state = reactive({
  checkIs:true,
  //当前签署人所有指定签署位置 0 签章，1 签名，2 日期，3 骑缝章
  sealpositionDatas: computed(() => {
    return store.state.sign.sealpositionDatas//签章
  }),
  signaturepositionDatas: computed(() => {
    return store.state.sign.signaturepositionDatas//签名
  }),
  datepositionDatas: computed(() => {
    return store.state.sign.datepositionDatas//日期
  }),
  allSignaturePositionDatas: computed(() => {
    return store.state.sign.allSignaturePositionDatas
  })
})
const butClick=()=>{
  if(state.checkIs)
    handleMessage('尚未完成签署位置指定')
}
const props = defineProps({
  baseInfo: {
    type: Object,
  },
});
const checkData=()=>{
  // 判断所有指定的签署位置是否已全部签署
  // console.log("check Data: ", state.sealpositionDatas.length)
  // state.sealpositionDatas = store.state.sign.sealpositionDatas
  // console.log("check Data: ", store.state.sign.sealpositionDatas.length)
  for(let i=0;i<state.sealpositionDatas.length;i++){
    // console.log('state.sealpositionDatas[i]',state.sealpositionDatas[i])
    if(!state.sealpositionDatas[i].nowSignData){
      state.checkIs=true
      return
    }
  }
  for(let i=0;i<state.signaturepositionDatas.length;i++){
    if(!state.signaturepositionDatas[i].nowSignData){
      state.checkIs=true
      return
    }
  }
  for(let i=0;i<state.datepositionDatas.length;i++){
    if(!state.datepositionDatas[i].nowSignData){
      state.checkIs=true
      return
    }
  }
  state.checkIs=false
}
watch(() => store.state.sign.checkTime, (value, oldValue) => {
  checkData()
})
defineExpose({
  state
})
</script>
<style lang="scss" scoped>
// @import "@/styles/general/element.scss"; //element 样式重置

.navigation {
  width: 100%;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  padding: 0 30px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #292728;

  .title {
    font-size: 22px;
    color: #333333;
  }
}</style>